.main-header {
  min-height: 20vh;
  align-items: center;
  justify-content: center;
}

.list-item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sticky-header {
  background-color: #1A202C;
}

.chakra-ui-light .sticky-header {
  background-color: #FFFFFF;
}

.animated-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

.hover-shade {
  width: 100vw;
  height: 100vh;
  background: rgb(26, 32, 44);
  background: linear-gradient(180deg, rgba(26, 32, 44, 1) 0%, rgba(26, 32, 44, 0.8982186624649859) 65%, rgba(26, 32, 44, 0) 100%);
}

.chakra-ui-light .hover-shade {
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.8982186624649859) 65%, rgba(255, 255, 255, 0) 100%);
}

.relative {
  position: relative;
}

.z-index-100>div>div {
  z-index: 100;
}

.report-text {
  font-size: medium;
  font-style: italic;
  color: #FFFFFFaa;
  padding-bottom: 10px;
}

.smaller-text{
  font-size: 14px;
  font-style: normal;
}

.chakra-ui-light .report-text {
  color: #000000aa;
}

.link-text {
  opacity: 1;
  color: #FFFFFF;
  text-decoration: underline;
}

.chakra-ui-light .link-text {
  color: #000000;
}

.icons {
  justify-self: flex-end;
}


.github-icon {
  filter: invert(1);
  width: 20px;
  height: 20px;
  margin: 10px;
  transition: .3s
}

.chakra-ui-light .github-icon {
  filter: invert(0);
  transition: .3s
}
